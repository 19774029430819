import React from 'react';
import logo from './meinual.png';

import { cellphone } from './config';

import './App.css';

function App() {
  const message = "Gostaria de abrir uma mei"
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Tem dúvidas sobre MEI?
        </h1>
        <h2>
          Que tal uma forma descomplicada de encontrar informações :)
        </h2>
        <div class="form__group">
          <input type="text" class="form__input" id="name" placeholder="Sua pergunta" required="" />
          <label for="name" class="form__label">Sua pergunta</label>
        </div>
        <a
          className="App-link"
          href={`https://wa.me/${cellphone}?text=${message}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Envie sua pergunta!
        </a>
      </header>
    </div>
  );
}

export default App;
